<template>
  <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
    <el-form ref="dataForm" :model="formData" :rules="rules" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="formData.name" clearable maxlength="20" placeholder="请输入图片名称" />
      </el-form-item>
      <el-form-item v-if="formVisible" label="添加图片" prop="image">
        <Imgpond v-model="formData.image" :count="1" />
      </el-form-item>
      <el-form-item label="链接" prop="link">
        <el-input v-model="formData.link" clearable maxlength="500" placeholder="请输入链接" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="padding-left: 140px">
      <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
      <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/cloud'
export default {
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      formVisible: false, //表单显示状态
      formData: {}, //表单数据
      rules: {
        name: [{ required: true, message: '请输入图片名称', trigger: 'blur' }],
        link: [{ required: true, message: '请输入链接', trigger: 'blur' }],
        image: [{ required: true, message: '添加图片', trigger: 'blur' }],
        sort: [{ required: true }],
        status: [{ required: true }]
      }
    }
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            name: '',
            link: '',
            image: '',
            sort: 0,
            status: 1
          }
        }
      } else {
        this.$refs.dataForm.resetFields()
        this.formData = {}
      }
    }
  },
  methods: {
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64)
      if (result) {
        console.log(result.url)
        this.formData.image = result.url
      } else {
        this.$message.error('图片失败')
        this.formData.image = ''
      }
    },
    submit() {
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
        this.formData.type = 'cooperation'
        const operation = this.formData._id ? 'updateCooperation' : 'addCooperation'
        const response = await http.POST('/jw-admin', {
          module: 'cooperation',
          operation: operation,
          project: JSON.parse(this.project)._id,
          ...this.formData
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
