<template>
  <div class="content">
    <div class="bg">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-circle-plus-outline" class="mr20" @click="$refs.formDialog.formVisible = true">新增合作伙伴</el-button>
      </div>
      <!-- 列表 -->
      <el-table :data="list" height="calc(100vh - 200px)">
        <el-table-column label="名称" width="200">
          <span class="title" slot-scope="scope">{{ scope.row.name }}</span>
        </el-table-column>
        <el-table-column prop="image" label="图片" width="500" align="center">
          <img slot-scope="scope" :src="scope.row.image" style="width: 60px; height: 60px" mode="aspectFill" />
        </el-table-column>
        <el-table-column prop="link" label="链接" align="left"></el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" @click="edit(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" @click.native.prevent="toDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 新增、编辑 -->
      <users-manage ref="formDialog" @refreshData="getCooperation"></users-manage>
    </div>
  </div>
</template>

<script>
import usersManage from './users-manage.vue'
import http from '@/utils/cloud'
export default {
  components: {
    usersManage
  },
  data() {
    return {
      list: [],
      project: localStorage.getItem('mall-project')
    }
  },
  created() {
    this.getCooperation()
  },
  methods: {
    /* 获取列表 */
    async getCooperation() {
      const list = await http.POST('/jw-admin', {
        module: 'cooperation',
        operation: 'getCooperationList',
        project: JSON.parse(this.project)._id
      })
      console.log(list)
      this.list = list
    },
    /* 删除 */
    toDelete(index, item) {
      this.$confirm(`是否要删除图片：${item.name}`, '删除提示', {
        confirmButtonText: '删除',
        type: 'warning'
      })
        .then(async () => {
          const response = await http.POST('/jw-admin', {
            module: 'cooperation',
            operation: 'deleteCooperation',
            id: item._id
          })

          if (response.status === 1) {
            this.$message.success('删除成功')
            this.list.splice(index, 1)
          } else {
            this.$message.error(response.msg)
          }
        })
        .catch(() => {})
    },
    /* 编辑 */
    edit(item) {
      this.$refs.formDialog.formData = JSON.parse(JSON.stringify(item))
      this.$refs.formDialog.formVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  height: 100%;
}
</style>
